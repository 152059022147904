/***************************************************
  Imports
***************************************************/

import React from "react"

import Layout from "../../components/Layout"

import FeaturedGraphic from "../../images/microsoft-powerapps.png"
import EmpowerGrapic from "../../images/microsoft-powerapps-empower.png"
import EnableGrapic from "../../images/microsoft-powerapps-enable.png"
import BuildGraphic from "../../images/microsoft-powerapps-build.png"
import WokflowGraphic from "../../images/microsoft-powerapps-workflow.png"

/***************************************************
  Page Data
***************************************************/

const benefits = [
  {
    title: "Empower Everyone to Build Apps",
    text:
      "Building apps with PowerApps helps everyone from business analysts to professional developers work more efficiently together. Solve business problems with intuitive visual tools that don’t require code, work faster with a platform that enables data integration and distribution, and extend the platform with building blocks for professional developers. Easily build and share apps on any device.",
    img: EmpowerGrapic,
  },
  {
    title: "Enable Your Business to Innovate",
    text: `PowerApps enables a broad range of app scenarios to be created that infuse digital transformation into manual and outdated processes. Use both canvas and model-driven apps to build PowerApps that solve business problems for task and role-specific scenarios like inspections, field sales enablement, prospect to cash, and integrated marketing views.`,
    img: EnableGrapic,
  },
  ,
  {
    title: "Build Highly Tailored Applications",
    text: `SimCrest can help by designing your user experience on the PowerApps canvas. We can customize every detail of your app to optimize for specific tasks and roles. Apps can be created for any device that uses a wide variety of controls including cameras and location, or we can start from a sample app showcasing common business scenarios, like expense reporting or site inspections.`,
    img: BuildGraphic,
  },
  ,
  {
    title:
      "Let Simcrest Create Sophisticated Apps with Workflows from Your Data",
    text: `We begin with your data model and business processes to automatically generate immersive, responsive applications that can run on any device. We use a simple drag-and-drop designer to customize business entities and tailor the user experience to specific roles.`,
    img: WokflowGraphic,
  },
  ,
  {
    title: "Use Microsoft Power Automate to add logic for your PowerApps",
    text: `Use Microsoft Power Automate to design logic for your PowerApps. Instead of writing code, we use the point-and-click automation designer to build out your business logic. Easily run flows in your app with buttons, actions, and data inputs. These automations can fire-and-forget, or return data back to your app to display information to the user.`,
  },
]

/***************************************************
  Component
***************************************************/

export default () => {
  const benefitsUI = () =>
    benefits.map((benefit, index) => {
      return (
        <>
          <h2 className="col-4 span-4">{benefit.title}</h2>
          <p className="col-9 span-4">{benefit.text}</p>

          {benefit.img && <img className="col-4 span-9" src={benefit.img} />}
        </>
      )
    })

  return (
    <Layout
      headerTheme="light"
      className="microsoft-powerapps"
      title="Microsoft PowerApps"
      description="Get high productivity apps you need for your business with a rich set of tools."
    >
      <section className="section-hero">
        <div className="col-3 span-5">
          <h1>Microsoft PowerApps</h1>
          <h2>Get the right app for the right job</h2>
          <p>
            Get high productivity apps you need for your business with a rich
            set of tools.
          </p>
        </div>
        <img
          className="col-8 span-6"
          src={FeaturedGraphic}
          alt="Microsoft PowerApps"
        />
      </section>

      <section className="section-benefits">{benefitsUI()}</section>
    </Layout>
  )
}
